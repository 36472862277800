<template>
  <div>
    <form-builder :schema="schema" />

    <div class="row">
      <div class="col-md-3">
        <div class="list-group list-primary">
          <div
            v-for="service of servicePoints.features"
            :key="service.id"
            class="list-group-item list-group-item-action flex-column list-item--custom"
            @click="handleMarkerSelect(service)"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ service.properties.name }}
              </h5>
            </div>

            <p class="mb-1">
              {{ service.properties.rawAddress }}
            </p>

            <small>{{ service.properties.rawTimetable }}</small>
          </div>
        </div>
      </div>

      <div class="col-md-9">
        <!-- <l-map
          ref="servicePointsMap"
          class="h-100 w-100"
          :zoom="zoom"
          :center="center"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
          />

          <l-layer-group ref="features">
            <l-popup>
              <div class="custom-popup">
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="m-auto">
                    {{ currentPoint.properties.name }}
                  </h6>
                </div>

                <p class="mb-1">
                  {{ currentPoint.properties.rawAddress }}
                </p>

                <small class="mb-2 d-block">{{ currentPoint.properties.rawTimetable }}</small>

                <div class="btn-group d-flex">
                  <button
                    class="btn btn-primary m-auto"
                    @click="commitService()"
                  >
                    {{ $t('Choose') }}
                  </button>
                </div>
              </div>
            </l-popup>
          </l-layer-group>

          <l-marker
            v-for="(point, i) of servicePoints.features"
            :key="i"
            :lat-lng="[point.geometry.coordinates[1], point.geometry.coordinates[0]]"
            @click="handleMarkerSelect(point)"
          >
            <l-icon
              :icon-size="getMarkerSize(point.geometry.coordinates)"
              :icon-url="isChoosed(point.geometry.coordinates) ? './assets/img/reverseMarker.png' : './assets/img/marker.png'"
            />
          </l-marker>
        </l-map> -->
      </div>
    </div>
  </div>
</template>

<script>
// Services
import { HALApiService } from '../../services'
import _ from 'lodash'

// Vuex
import { mapGetters, mapMutations } from 'vuex'

// import { Icon } from 'leaflet'
// delete Icon.Default.prototype._getIconUrl

// Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// })

export default {
  emits: ['delivery-request', 'change'],
  components: {
    // 'l-map': () => import('vue2-leaflet').then(m => m.LMap),
    // 'l-tile-layer': () => import('vue2-leaflet').then(m => m.LTileLayer),
    // 'l-marker': () => import('vue2-leaflet').then(m => m.LMarker),
    // 'l-icon': () => import('vue2-leaflet').then(m => m.LIcon),
    // 'l-popup': () => import('vue2-leaflet').then(m => m.LPopup),
    // 'l-layer-group': () => import('vue2-leaflet').then(m => m.LLayerGroup)
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    deliveryServices: {
      type: Array,
      default () {
        return []
      }
    },
    defaultCenter: {
      type: Array,
      default () {
        return []
      }
    },
    model: {
      type: Object,
      default () {
        return {
          _embedded: {
            recipientLocality: {},
            recipientAddress: {}
          }
        }
      }
    }
  },
  data () {
    return {
      servicePoints: [],
      zoom: 13,
      center: [43.204666, 27.910543],
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: null,
      currentPlace: null,
      currentPoint: {
        properties: {}
      },
      features: null,
      localities: [],
      officialDeliveryServices: [],
      deliveryRequest: this.model
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    schema () {
      return {
        groups: [
          {
            legend: this.$t('Address'),
            fields: [
              {
                type: 'address',
                label: this.$t('Address'),
                value: this.deliveryRequest._embedded.recipientAddress.notFormal,
                field: 'notFormal',
                required: true,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                googleApiKey: this.appOptions.googleApiKey,
                onInput: (notFormal) => {
                  this.deliveryRequest._embedded.recipientAddress.notFormal = notFormal
                },
                onPlaceChanged: (value, place) => {
                  this.setPlace(value)
                  this.addMarker()

                  let localAddress = {
                    postcode: place.postal_code,
                    notFormal: this.deliveryRequest._embedded.recipientAddress.notFormal,
                    _embedded: {}
                  }

                  if (place.route !== undefined) {
                    localAddress.street = place.route
                  }

                  if (place.street_number !== undefined) {
                    localAddress.house = place.street_number
                  }

                  localAddress.geo = {
                    latitude: value.y,
                    longitude: value.x
                  }

                  if (place.postal_code !== undefined) {
                    localAddress.postcode = place.postal_code

                    return this.getLocalityByPostcode(localAddress.postcode)
                      .then(locality => {
                        localAddress._embedded.locality = locality

                        this.deliveryRequest._embedded.recipientAddress = localAddress

                        return localAddress
                      })
                      .catch(error => {
                        this.addErrorNotification(error)
                        this.stopLoading()
                      })
                  } else {
                    this.deliveryRequest._embedded.recipientAddress = localAddress
                  }
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Postcode'),
                value: this.deliveryRequest._embedded.recipientAddress.postcode,
                field: 'postcode',
                required: true,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-4 q-pa-xs',
                onChange: (postcode) => {
                  let localAddress = _.cloneDeep(this.deliveryRequest._embedded.recipientAddress)

                  if (postcode.length >= 4) {
                    localAddress.postcode = postcode

                    return this.getLocalityByPostcode(postcode)
                      .then(locality => {
                        if (!localAddress._embedded) {
                          localAddress._embedded = {}
                        }

                        localAddress._embedded.locality = locality

                        this.deliveryRequest._embedded.recipientAddress = localAddress

                        return localAddress
                      })
                      .catch(error => {
                        this.addErrorNotification(error)
                      })
                  }
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Location'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.deliveryRequest._embedded.recipientLocality,
                field: 'recipientLocality',
                required: false,
                disabled: this.disabled,
                minimumLength: 3,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.type + ' ' + row.name + (row.postcode ? ', ' + row.postcode : '') + (row._embedded && row._embedded.area.name ? ' (' + row._embedded.area.name + ')' : '')
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: (value) => {
                  this.deliveryRequest._embedded.recipientLocality = value

                  if (value && value.postcode) {
                    this.deliveryRequest._embedded.recipientAddress.postcode = value.postcode
                  }
                }
              },
              {
                type: 'select',
                label: this.$t('Pick up point'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.deliveryRequest._embedded.servicePoint,
                field: 'servicePoint',
                required: false,
                disabled: this.servicePoints.features && this.servicePoints.features.length === 0,
                options: this.servicePoints.features || [],
                customLabel (row) {
                  return row && row.properties
                    ? `${row.properties.name} (${row.properties.extId})`
                    : ''
                },
                onChange: (newVal) => {
                  this.currentPoint = newVal
                  this.commitService()
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    model: {
      handler (newValue) {
        this.deliveryRequest = newValue
      },
      deep: true
    },
    deliveryRequest: {
      handler (newValue) {
        if (this.deliveryRequest._embedded.recipientLocality) {
          const query = [
            {
              type: 'eq',
              field: 'state',
              value: 'active'
            },
            {
              type: 'eq',
              field: 'locality',
              value: this.deliveryRequest._embedded.recipientLocality.id
            }
          ]

          this.loadServicePoints(null, query, 'application/geo+json')
            .then(() => {
              this.updateMapSize()
              const { latitude, longitude } = this.deliveryRequest._embedded.recipientLocality.geo
              this.center = [latitude, longitude]
            })
        }

        this.$emit('delivery-request', newValue)
      },
      deep: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.geolocate()
      this.updateMapSize()
    })
  },
  updated () {
    this.updateMapSize()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'startLoading',
      'stopLoading'
    ]),
    loadServicePoints (name = null, params = [{ type: 'eq', field: 'state', value: 'active' }], accept = 'application/json') {
      this.startLoading()

      let query = {
        per_page: 250,
        page: 1
      }

      if (Object.keys(params).length > 0) {
        query.filter = params
      }

      if (name !== null) {
        query.search = name
      }

      return HALApiService.get('/delivery-services/service-points', query, accept)
        .then(data => {
          if (accept === 'application/geo+json') {
            this.servicePoints = data
          } else {
            this.servicePoints = data._embedded.servicePoints
          }

          this.stopLoading()

          return data
        })
        .catch(error => {
          this.addErrorNotification(error)

          this.stopLoading()
        })
    },
    commitService () {
      this.$emit('change', this.currentPoint.properties.id)
    },
    handleMarkerSelect (point) {
      const coords = point.geometry.coordinates
      this.currentPoint = point
      this.marker = [coords[1], coords[0]]
      this.openPopUp(this.marker)
    },
    getMarkerSize (coords) {
      return this.isChoosed(coords)
        ? [34, 50]
        : [26, 40]
    },
    isChoosed (coords) {
      return !!this.marker && coords[0] === this.marker[1] && coords[1] === this.marker[0]
    },
    openPopUp (latLng) {
      this.$refs.features.mapObject.openPopup(latLng)
    },
    updateMapSize () {
      this.$refs.servicePointsMap.mapObject.invalidateSize()
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = [position.coords.latitude, position.coords.longitude]
      })
    },
    getLocalityByPostcode (postcode) {
      if (this.officialDeliveryServices.length === 0 && this.deliveryServices.length > 0) {
        this.deliveryServices.forEach(val => {
          if (val.isOfficial === true) {
            this.officialDeliveryServices.push(val.id)
          }
        })
      }

      if (postcode !== undefined && postcode.length < 4) {
        return Promise.reject(new Error('Postcode too short'))
      }

      this.startLoading()

      const query = {
        filter: [
          {
            type: 'eq',
            field: 'extId',
            value: postcode
          },
          {
            type: 'in',
            field: 'deliveryService',
            values: this.officialDeliveryServices
          }
        ]
      }

      return HALApiService.get('/delivery-services/service-points', query)
        .then(data => {
          this.stopLoading()

          if (data.total_items === 1) {
            let locality = data._embedded.servicePoints[0]._embedded.locality

            this.localities = []
            this.localities.push(locality)

            return Promise.resolve(locality)
          } else if (data.total_items === 0) {
            return Promise.reject(new Error(this.$t('No localities found')))
          } else {
            this.localities = []
            data._embedded.servicePoints.forEach((servicePoint) => {
              if (servicePoint._embedded && servicePoint._embedded.locality) {
                this.localities.push(servicePoint._embedded.locality)
              }
            })

            return Promise.reject(new Error(this.$t('Several localities found')))
          }
        })
        .catch(error => {
          this.addErrorNotification(error)

          this.stopLoading()
        })
    },
    // Receives a place object via the autocomplete component
    setPlace (place) {
      this.currentPlace = place
    },
    addMarker () {
      if (this.currentPlace) {
        this.marker = {
          lat: this.currentPlace.y,
          lng: this.currentPlace.x
        }

        this.center = this.marker
        // this.markers.push({ position: marker })
        // this.places.push(this.currentPlace)
        // this.center = marker
        // this.currentPlace = null
      }
    },
    loadLocalities (name = null, params = [{ type: 'eq', field: 'type', value: 'город' }]) {
      this.startLoading()

      let query = {
        per_page: 25,
        page: 1
      }

      if (Object.keys(params).length > 0) {
        query.filter = params
      }

      query.filter = []

      if (name !== null) {
        query.search = name
      }

      HALApiService.get('/locations/localities', query)
        .then(data => {
          this.localities = data._embedded.localities

          this.stopLoading()
        })
        .catch(error => {
          this.addErrorNotification(error)

          this.stopLoading()
        })
      // getFromServer(this.serverParams).then(response => {
      //   this.totalRecords = response.totalRecords;
      //   this.rows = response.rows;
      // });
    }
  }
}
</script>

<style>
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

  .list-primary {
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .list-item--custom {
    cursor: pointer;
  }

  .list-item--custom:hover {
    background: #f3f4f5;
  }

  .custom-popup {
    min-width: 220px;
    text-align: center;
  }
</style>
